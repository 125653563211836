@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.content-editor {
  .p-dialog-mask {
    position: absolute;

    .p-dialog.p-component {
      margin: 0;

      .p-dialog-content {
        //overflow-y: visible;
      }
    }

    .p-dialog-footer {
      padding: 0;
    }
  }
}

//e2e-toolbar-menu .p-menubar .p-menubar-end {
//  margin-left: unset;
//}

.p-card-content {
  padding: 0;
}

.p-toolbar,
.p-menubar {
  border: none;
}

.p-sidebar {
  a {
    color: white;
    text-decoration: none;
  }

  .p-sidebar-header {
    padding: 0;
  }

  &.p-sidebar-left {
    width: 360px;
  }
}


.p-carousel-content {
  //width: 450px;
}


:root {
  --font-sans: ''
}

.modal-box {
  .content-wrapper {
    //padding-left: 18px;
    //border-left-width: 6px;
    //border-left-style: dashed;
    //border-left-color: rgba(189, 59, 38, 0.9);
  }
  //outline: solid 3px rgba(189, 59, 38, 0.9);
  //box-shadow: 0 0 30px 10px rgba(189, 59, 38, 0.9);
  padding-top: .5rem;
}

.modal::backdrop {
  background-color: #000c;
}
